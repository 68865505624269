.site-carousel{
    // main books collections carousel
    align-items: flex-start;
    &#tns1{
        .slider-nav__item{
            .component {
                &__link {
                    margin-top: 16px;
                    @media (min-width: 40em){
                        min-height: 50px;
                    }
                    .book-title {
                        text-transform: capitalize;
                        border-radius: 10px;
                        border: 8px solid transparent;
                        padding: 8px;
                        &:hover {
                            background-color: $purple;
                            color: $white;
                            border-color: $white;
                        }
                    }
                }
            }

            &.is-active {
                .component__link{
                    .book-title {
                        background-color: $purple;
                        color: $white;
                        border-color: $white
                    }
                }
            }
        }
    }
}
.authors, .illustrators {
    main {
        background: $cream;
        h1 {
            color: $red;
            text-align: center;
            @include breakpoint(large-tablet) {
                text-align: start;
            }
        }
        .purple-text-colour {
            h1 {
                color: $purple;
                text-align: center;
            }
        }
        p {
            margin-bottom: 32px;
        }
    }
}
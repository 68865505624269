$white: white;
$black: #000;
$dark-grey: #454545;
$orange: #D56F4F;
$purple: #4f4696;
$cream: #fdf6bd;
$yellow: #f9ce5b;
$red: #F06564;
$blue: #9DDBF6;
$dark-blue: #0055A5;
$mid-blue: #6D8FBE;
$sky-blue: #bee5fb;
$rose: #d47380;
$pink: #EBA7D8;
$green: #3D9059;

$max-width: 1060px;
$animation-time: 0.3s;
$section-border-top: $red;
$background-body: $white;
$body-font-color: $dark-grey;
$primary-color: $orange;
$secondary-color: $purple;
$background-buy-section: $yellow;

$assetBackgrounds: $blue, $red, $green, $mid-blue, $pink, $purple;

/* Header */

$header-background-color: $sky-blue;

/* NAVIGATION */

$nav-links-colour: $cream;
$nav-links-colour-hover: $blue;
$nav-links-background-colour-hover: $purple;
$nav-current-link-colour: $blue;
$nav-current-link-background-colour: $purple;
$nav-background-colour: $purple;

/* default font sizes */

$base-font-size: 18px;
$h1-font-size: 70px;
$h2-font-size: 36px;
$h3-font-size: 24px;
$h4-font-size: 18px;
$h5-font-size: 16px;

.books {
    .component__text {
        h1 {
            margin-bottom: 8px;
        }
        &.grid-subtitle {
            margin: 0 auto;
            max-width: 600px;
        }
    }
    .book-landing {
        .pagination-top, .pagination-bottom {
            .pagination {
                li {
                    margin-bottom: 16px;
                }
            }
        }
        .book-card {
            @media (max-width: 767px) {
                padding: 10px 4px;
            }
            ul {
                min-height: 180px;
            }
            .book-title {
                padding: 16px 0 8px;
                a {
                    color: $purple;
                    line-height: 27px;
                    @media (max-width: 767px) {
                        font-size: 16px;
                    }
                }
            }
            .book-price {
                font-size: 22px;
            }
        }
    }
}

.newsletter {
    h2 {
        color: $cream;
        text-align: center;
        padding-bottom: 16px;
        font-size: 28px;
        text-transform: uppercase;
        @media (min-width: 768px) {
            text-align: left;
            padding: 0 32px 0 0;
            font-size: 32px;
        }
    }
    .sailthru-form {
        &__email {
            label {
                text-transform: capitalize;
                color: $white;
            }
        }
        &__dob {
            label {
                text-transform: capitalize;
                color: $white;
            }
            .select-style {
                border-radius: 8px;
            }
        }
        &__legal {
            color: $white;
            a {
                &:hover {
                    color: $sky-blue;
                }
            }
        }
        &__submit {
            button {
                background-color: $cream;
                color: $purple;
                text-transform: uppercase;
                font-size: 26px;
                &:hover {
                    filter: brightness(1.2);
                }
            }
        }
        &__restricted-age {
            p {
                color: $white;
            }
        }
    }
}
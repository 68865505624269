.characters {
    .character {
        &__subtitle {
            p {
                margin-bottom: 0;
                color: $white;
            }
        }
        &__info {
            h1 {
                color: $rose;
                @media (max-width: 767px) {
                    margin-bottom: 16px;
                    text-align: center;
                }
            }
            p {
                margin-bottom: 32px;
                @media (max-width: 767px) {
                    &:last-of-type {
                        border-bottom: 4px solid $rose;
                        padding-bottom: 32px;
                        margin-bottom: 0;
                    }
                }
            }
            @media (min-width: 768px) {
                border-bottom: 2px solid $rose;
            }
        }
        &__related-books {
            @media (min-width: 768px) {
                border-bottom: 2px solid $rose;
            }
            .books-carousel {
                @media (max-width: 767px) {
                    padding-bottom: 32px;
                    border-bottom: 4px solid $rose;
                }
                .tns-controls {
                    left: -10px;
                    right: -10px;
                }
                .book-card {
                    padding: 10px 16px;
                }
            }
        }
    }
    .character-nav {
        &__item {
            padding: 16px 4px;
            @include breakpoint(large-tablet) {
                padding: 32px;
            }
            .component {
                &__image {
                    position: relative;
                    z-index: 1;
                    a {
                        max-width: 125px;
                        transition: $animation-time all;
                        position: relative;
                        &:after {
                            content: '';
                            position: absolute;
                            top: 0;
                            left: 0;
                            bottom: 0;
                            right: 0;
                            z-index: -1;
                            border-radius: 50%;
                            transition: all 0.3s;
                        }
                        &:hover, &:active {
                            &:after {
                                top: -10px;
                                left: -10px;
                                bottom: -10px;
                                right: -10px;
                                background-color: #78B0CF;
                                border-radius: 50%
                            }
                        }
                        @include breakpoint(large-tablet) {
                            max-width: 175px;
                        }
                    }
                }
                &__link {
                    a {
                        @extend %primary-font;
                        font-size: 32px;
                        line-height: 26px;
                        color: $white;
                        border: 8px solid transparent;
                        border-radius: 10px;
                        padding: 16px;
                        transition: $animation-time all;
                        margin-top: -12px;
                        &:hover, &:active {
                            background: #78B0CF;
                            border-color: $white;
                        }
                        @include breakpoint(large-tablet) {
                            font-size: 38px;
                            line-height: 26px;
                        }
                    }
                }
            }
            &.is-active {
                .component {
                    &__image {
                        a {
                            &:after {
                                top: -10px;
                                left: -10px;
                                bottom: -10px;
                                right: -10px;
                                background-color: #78B0CF;
                                border-radius: 50%
                            }
                        }
                    }
                    &__link{
                        a {
                            background: #78B0CF;
                            border-color: $white;
                        }
                    }
                } 
            }
            @include breakpoint(large-tablet) {
                &:first-of-type {
                    .component {
                        &__image {
                            justify-content: flex-end;
                            a {
                                margin-right: 48px;
                            }
                        }
                        &__link {
                            justify-content: flex-end;
                        }
                    }
                }
                &:last-of-type {
                    .component {
                        &__image {
                            justify-content: flex-start;
                            a {
                                margin-left: 48px;
                            }
                        }
                        &__link {
                            justify-content: flex-start;
                        }
                    }
                }
            }
        }
    }
}
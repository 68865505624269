.header {
  background-color: $header-background-color;
  &__logo {
    max-width: none;
    a[href="https://www.harpercollins.com/childrens/"] {
      right: 7%;
    }
    &__kids-image {
      width: 65px;
    }
  }
}

@import "variables";
@import "typography";

body {
    background: $background-body;
}

main {
    overflow: hidden;
}

.white-background {
    background-color: $white;
    @include full_width_colour($white);
}

.red-background {
    background-color: $red;
    @include full_width_colour($red);
}

.yellow-background {
    background-color: $yellow;
    @include full_width_colour($yellow);
}

.cream-background {
    background-color: $cream;
    @include full_width_colour($cream);
}

.blue-background { 
    background-color: $dark-blue; 
    @include full_width_colour($dark-blue);
}

.purple-background {
    background-color: $purple;
    @include full_width_colour($purple);
}

.rose-background {
    background-color: $rose;
    @include full_width_colour($rose);
}
.home {
  .main-hero {
    h2 {
      margin-bottom: 16px;
      font-size: 42px;
      line-height: 36px;
      text-transform: none;
      @include breakpoint(large-tablet) {
        font-size: 70px;
        line-height: 52px;
      }
    }
    .hero {
      &__container {
        &--left {
          @media (min-width: 768px) and (max-width: 1023px) {
            &.md\:w-2\/5 {
              width: 50%;
            }
          }
        }
        &--right {
          @media (min-width: 768px) and (max-width: 1023px) {
            &.md\:w-3\/5 {
              width: 50%;
            }
          }
        }
      }
    }
  }
  .secondary-hero {
    .hero {
      h2 {
        @extend %main-font;
        margin-bottom: 64px;
        font-size: 15px;
        line-height: 22px;
        text-transform: none;
        letter-spacing: 0;
        color: $body-font-color;
        margin: 32px 0;
        @include breakpoint(large-tablet) {
          margin: 16px 0 64px;
          font-size: 18px;
          line-height: 27px;
        }
      }
      &__container {
        &--left {
          order: 2;
          @media (min-width: 768px) {
            &.md\:w-2\/5 {
              width: 50%;
            }
          }
        }
        &--right {
          order: 1;
          @media (min-width: 768px) {
            &.md\:w-3\/5 {
              width: 50%;
            }
          }
        }
      }
    }
  }

  .home-carousel {
    @media (min-width: 900px) and (max-width: 1280px) {
      max-width: 95%;
    }
  }

  .home-blockquote {
    .site-carousel {
      align-items: center;
    }
  }
}